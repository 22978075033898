import { StickyFooter } from '@/components'
import { APIQueryParamTypes, useNavigateWithLegalEntityID, useNavigationRoutes, usePermissions } from '@/hooks'
import { QuickFilterParamTypes, updateUUIDRelativeActionInPathname } from '@/providers'
import {
    getReconcilableAmount,
    getReconciledAmount,
    isCreateReconciliationAvailable,
    isReconcilablePaymentObjectType,
    PERMISSION
} from '@/services'
import { amountFormatter } from '@/utils/@formatters'
import { Alert, Box, Button, Flex, Stack, Text } from '@chakra-ui/react'
import { Amount, ApiObjectTypeSchema, ReconciliationStatusSchema } from '@webapps/numeral-ui-core'
import React, { useCallback, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { ReconciliationComputedStatusProps } from './ReconciliationComputedStatus.types'
import {
    computeReconciliationComputedStatusAlertProps,
    getReconciliationComputedStatusDescription,
    isReconciliationStatusVisible
} from './ReconciliationComputedStatus.utils'
import {
    getReconcilableTargetObjectAbsolutePath,
    getReconcilableTargetObjectRelativeReconciliationsPath,
    useReconciliation
} from '@/pages'

export const ReconciliationComputedStatus: React.FC<ReconciliationComputedStatusProps> = ({ item }) => {
    const intl = useIntl()
    const location = useLocation()
    const { navigateWithLegalEntityID } = useNavigateWithLegalEntityID()
    const { hasPermission } = usePermissions()
    const { onStartReconciliation } = useReconciliation()
    const { relativePaths } = useNavigationRoutes()
    const isVisible = useMemo<boolean>(() => {
        return isReconciliationStatusVisible(item?.reconciliation_status)
    }, [item])
    const remainingAmount = useMemo<Amount>(() => {
        return getReconcilableAmount(item)
    }, [item])
    const reconciledAmountFormatted = useMemo<string | undefined>(() => {
        const amount = getReconciledAmount(item)
        return amountFormatter(intl, amount, item?.currency)
    }, [item, intl])
    const remainingAmountFormatted = useMemo<string | undefined>(() => {
        return amountFormatter(intl, remainingAmount, item?.currency)
    }, [intl, remainingAmount, item])
    const labelId = useMemo<string>(() => {
        return isReconcilablePaymentObjectType(item)
            ? 'app.reconciliations.status.actions.reconcile_transaction.label'
            : 'app.reconciliations.status.actions.reconcile_payment.label'
    }, [item])
    const { status, title, description, variant } = useMemo(() => {
        const partialProps = computeReconciliationComputedStatusAlertProps(remainingAmount, item)

        return {
            ...partialProps,
            title: intl.formatMessage(
                { id: partialProps?.title },
                {
                    reconciledAmount: reconciledAmountFormatted,
                    remainingAmount: remainingAmountFormatted
                }
            ),
            description: getReconciliationComputedStatusDescription(intl, item, partialProps?.description)
        }
    }, [remainingAmount, item, reconciledAmountFormatted, remainingAmountFormatted, intl])
    const [marginBottom, setMarginBottom] = useState<number>(0)
    const onReconcile = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation()

            /**
             * @description
             * 1 If the current object is a transaction, only the current path changes (for picking the payment type);
             * 2. If the current object is a not a transaction (but reconcilable payment type),
             * the reconciliation flow will start with it (`item`) against the transactions;
             */
            switch (item?.object) {
                case ApiObjectTypeSchema.enum.transaction: {
                    const { ACCOUNTS } = relativePaths
                    const path = updateUUIDRelativeActionInPathname(location.pathname, ACCOUNTS.RECONCILE)
                    navigateWithLegalEntityID(path)
                    break
                }

                default: {
                    onStartReconciliation(ApiObjectTypeSchema.enum.transaction, item)
                }
            }
        },
        [location, navigateWithLegalEntityID, relativePaths, onStartReconciliation, item]
    )

    const canCreateReconciliation = isCreateReconciliationAvailable(item?.reconciliation_status)
    const hasReconciliationsConfigurePermission = hasPermission(PERMISSION.RECONCILIATIONS_CONFIGURE_RECONCILIATIONS)

    if (!isVisible) {
        return null
    }

    return (
        <Box className="ReconciliationComputedStatus" marginBottom={marginBottom}>
            <StickyFooter onMount={setMarginBottom}>
                <Alert status={status} variant={variant}>
                    <Flex alignItems="center" justifyContent="space-between" flex="1">
                        <Stack>
                            <Text fontWeight="bold">{title}</Text>
                            <Text>{description}</Text>
                        </Stack>
                        {canCreateReconciliation && hasReconciliationsConfigurePermission && (
                            <Button onClick={onReconcile} variant="solid">
                                <FormattedMessage id={labelId} />
                            </Button>
                        )}
                    </Flex>
                </Alert>
            </StickyFooter>
        </Box>
    )
}
