import { Nullable } from '@/types'
import { Uuid, UuidSchema } from '@webapps/numeral-ui-core'
import { usePermissions, useQueryFindByIdAPIKey } from '@/hooks'
import { useMemo } from 'react'
import { getAPIKeyLast4CharsPlaceholderTemplate, PERMISSION } from '@/services'
import { FormattedMessage } from 'react-intl'
import { DetailPageLink } from '@/components/@misc'
import { ApiObjectTypeExtendedSchema } from '@/schemas'
import { isEmptyRecord } from '@/utils'

export const APIKeyName: React.FC<{ id: Nullable<Uuid> }> = ({ id }) => {
    const hasValidUUID = useMemo<boolean>(() => UuidSchema.safeParse(id).success, [id])
    const { hasPermission } = usePermissions()
    const hasViewAPIKeysPermission = useMemo(() => {
        return hasPermission(PERMISSION.DEVELOPERS_VIEW_API_KEYS)
    }, [hasPermission])
    const query = useQueryFindByIdAPIKey(id as string, {
        enabled: hasViewAPIKeysPermission && hasValidUUID
    })
    const last4Chars = useMemo(() => {
        return getAPIKeyLast4CharsPlaceholderTemplate(query.data)
    }, [query.data])
    const isDataEmpty = useMemo(() => isEmptyRecord(query.data), [query.data])

    if (!hasViewAPIKeysPermission) {
        return <FormattedMessage id="api.object.api_key" />
    }

    if (isDataEmpty) {
        return <FormattedMessage id="app.common.not_found.title" />
    }

    return (
        <DetailPageLink objectId={query.data?.id} objectType={ApiObjectTypeExtendedSchema.enum.api_key}>
            <FormattedMessage id="api.object.api_key" /> {last4Chars}
        </DetailPageLink>
    )
}
