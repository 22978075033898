import { chain } from 'lodash'
import { useMemo, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import {
    getPrivateTopLevelRoutes,
    NavigationRoute,
    PATHNAME_LEGAL_ENTITY_ID_WITH_PRECEDING_SYMBOL__REGEX
} from '../providers'
import { useNavigationRoutes } from './context/useNavigationRoutes'

interface UsePrivateTopLevelRoutesHook {
    routes: NavigationRoute[]
    currentRoute?: NavigationRoute
}

const getCurrentRootRoute = (currentPathname: string) => {
    const pathnameWithoutLegalEntity = currentPathname.replace(
        PATHNAME_LEGAL_ENTITY_ID_WITH_PRECEDING_SYMBOL__REGEX,
        ''
    )
    console.log()
    return chain(pathnameWithoutLegalEntity).split('/', 2).last().defaultTo(pathnameWithoutLegalEntity).value()
}
const flattenRoutes = (route: NavigationRoute[]): NavigationRoute[] => {
    return route.flatMap((item) => (item.routes ? [item, ...flattenRoutes(item.routes)] : item))
}

export const usePrivateTopLevelRoutes = (): UsePrivateTopLevelRoutesHook => {
    const location = useLocation()
    const { routes } = useNavigationRoutes()
    const privateTopLevelRoutes = useRef<NavigationRoute[]>(getPrivateTopLevelRoutes(routes)).current
    const currentPrivateTopLevel = useMemo<NavigationRoute | undefined>(() => {
        const currentRoot = getCurrentRootRoute(location.pathname)
        return privateTopLevelRoutes.find((route) => route?.path?.includes(currentRoot))
    }, [location, privateTopLevelRoutes])

    return {
        routes: privateTopLevelRoutes,
        currentRoute: currentPrivateTopLevel
    }
}
